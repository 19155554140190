<script setup>
import { defineProps, ref } from "vue"
import { useConfigStore } from "@/store/config"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"

const configStore = useConfigStore()
const confirm = useConfirm()
const toast = useToast()

const props = defineProps(['provider'])
const provider = ref(props.provider)

const banId = ref(null)
const loading = ref(false)

const banLists = ref([])

const banConfirm = () => {
  confirm.require({
    message: `Do you want to ban id ${banId.value} ?`,
    header: 'Ban Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: () => {
      loading.value = true

      configStore.banListsbanId({ id: banId.value, provider: provider.value }).then(({ data }) => {
        if (data.error) {
          toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
          return
        }

        toast.add({severity:'success', summary: 'Success', detail: `Success (#ban_lists_${provider.value})`, life: 3000})

        banId.value = null
        
        query()
      }).catch((e) => {
        toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
      }).finally(() => {
        loading.value = false
      })
    },
  })
}

const query = () => {
  loading.value = true

  configStore.show(`ban_lists_${provider.value}`).then(({ data }) => {
    if (! data.error) {
      banLists.value = data.config.value || []

      loading.value = false
    }
  })
}

const update = () => {
  loading.value = true

  configStore.update(`ban_lists_${provider.value}`, { value: banLists.value }).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: `Updated (#ban_lists_${provider.value})`, life: 3000})
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  }).finally(() => {
    loading.value = false
  })
}

query()

</script>

<template>
  <div class="block-section mb-3">
    <div class="block-header mb-2">
      <span class="block-title">
        <div>Ban Lists {{ provider }}</div>
      </span>
    </div>
    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 border-round w-full lg:w-6">
          <div class="p-fluid">
            <div class="card">
              <div>
                <label class="block text-900 font-medium mb-2" for="leverage">Ban ID ({{ provider }})</label>
                <div class="p-inputgroup flex-1">
                  <InputText v-model="banId" placeholder="Keyword" />
                  <Button 
                    icon="pi pi-trash" 
                    class="p-button-danger"
                    :loading="loading"

                    @click="banConfirm" 
                  />
                </div>
              </div>

              <hr />

              <div>
                <label class="block text-900 font-medium mb-2" for="leverage">Lists ({{ provider }})</label>
                <Chips
                  v-model="banLists"
                  
                  :max="banLists.length"
                />
              </div>
            </div>
          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="loading" @click="update" />
        </div>
      </div>
    </div>
  </div>
</template>
